/**
* --------------------------------------------------
* React Three Fiber
*
* @module Puzzle
* --------------------------------------------------
*/
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import { BlendFunction } from 'postprocessing'
import { OrbitControls, useGLTF, Environment, Lightformer, MeshTransmissionMaterial } from '@react-three/drei'
import { EffectComposer, LUT, TiltShift2, HueSaturation, Bloom, ChromaticAberration } from '@react-three/postprocessing'
import { useRef, useLayoutEffect, useMemo } from 'react' 
import { gsap } from 'gsap'
import * as THREE from 'three'
import SceneStatus from './SceneStatus'

export default function Puzzle() {
    return (
        <Canvas shadows="true" id="Puzzle" gl={{ antialias: false }} >
            <color attach="background" args={['#F0F0F0']} />
            {/* Lights */}
            <ambientLight />
            <spotLight position={[20,20,20]} angle={0.15} penumbra={1} intensity={2} castShadow />
            <pointLight position={[-10, -10, -10]} />
            {/* Shapes */}
            <MainPuzzleShape />
            <Environment resolution={256}>
                <group rotation={[-Math.PI / 3, 0, 1]}>
                    <Lightformer form="circle" intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={2} />
                    <Lightformer form="circle" intensity={2} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={8} />
                </group>
            </Environment>
            <EffectComposer disableNormalPass>
                <Bloom mipmapBlur luminanceThreshold={1} intensity={2} />
                <TiltShift2 blur={0.2} />
            </EffectComposer>
            <Scene moveByMouse={true}/>
        </Canvas>
    )
}

const Scene = (props) => {

    useThree(({ camera }) => {
        camera.position.set(4,0,0)
        camera.lookAt(0,0,0)
    })

    if(props.moveByMouse) {
        const cursor = {}
        cursor.x = -1.5 // start at center
        cursor.y = -1.5 // start at middle
        window.addEventListener('mousemove', (event) =>
        {
            cursor.x = event.clientX / window.innerWidth - 2
            cursor.y = event.clientY / window.innerHeight - 2
        })

        window.addEventListener('touchmove', (event) =>
        {
            cursor.x = event.touches[0].clientX / window.innerWidth - 2
            cursor.y = event.touches[0].clientY / window.innerHeight - 2
        })

        const { camera } = useThree()
        const vec = new THREE.Vector3()
        return useFrame(() => {
            camera.position.lerp(vec.set(camera.position.x, cursor.y * 0.25, cursor.x * 0.25), 0.1)
        })
    }
};

function MainPuzzleShape(props) {
    const { nodes } = useGLTF('./Order.gltf')

    let MesheShapes = [];
    let orderPositions = []
    let choasPositions = [
        {x: 1.656787633895874, y: -31.3205349445343018, z: 0.1989591270685196},
        {x: -29.227876663208008, y: -20.36952543258667, z: -13.9548227787017822},
        {x: -2.137575626373291, y: -4.080927610397339, z: 34.435920238494873},
        {x: 0.7918439507484436, y: 9.3290060758590698, z: -13.2011032104492188},
        {x: 3, y: -0.5, z: -1},// cool one
        {x: -8.26800537109375, y: -0.6959869265556335, z: 14.4411869049072266}, 
        {x: -3.271531581878662, y: 8.201204314827919, z: 2.755000352859497},
        {x: 26.1561533659696579, y: 0.8059977293014526, z: 13.79319167137146},
        {x: -60.335177421569824, y: 40.3588881492614746, z: -1.8402762413024902},
        {x: 0.2910090982913971, y: -0.23317325115203857, z: 4.355711936950684},
        {x: -6.497125625610352, y: -4.968991279602051, z: -3.9892289638519287},
        {x: 20.0470762252807617, y: -4.581718921661377, z: 1.7394921779632568},
        {x: 7.14618216454982758, y: 10.1784173250198364, z: 1.9092365503311157}, // cube
        {x: 25.043307781219482, y: 1.2396899461746216, z: -1.0465673208236694},
        {x: 3.026441812515259, y: 20.237462043762207, z: -12.258131265640259}, // second ball
        {x: 58.023603439331055, y: 21.2576539516448975, z: 10.24316848814487457}
    ]

    // set material for re-use 
    const materialGrayBasic = useMemo(() => new THREE.MeshPhysicalMaterial({roughness: 0.6,metalness: 0.05,reflectivity: 0.5,color: new THREE.Color(0x555555)}) )
    
    // render meshes from file
    let index = 0
    for (const shapeKey in nodes) {
        if (nodes[shapeKey].isMesh && shapeKey !== 'BG'){
            orderPositions.push(nodes[shapeKey].position)
            if(index == 4 || index == 14){ // index == 4 || index == 14
                MesheShapes.push(
                    <mesh key={shapeKey} receiveShadow castShadow rotation={nodes[shapeKey].rotation} position={[nodes[shapeKey].position.x, nodes[shapeKey].position.y, nodes[shapeKey].position.z]} geometry={nodes[shapeKey].geometry} {...props}>
                        <MeshTransmissionMaterial backsideThickness={10} thickness={10} samples={4}/>
                    </mesh>
                );
            }
            else {
                MesheShapes.push(
                    <mesh material={materialGrayBasic} key={shapeKey} receiveShadow castShadow rotation={nodes[shapeKey].rotation} position={[nodes[shapeKey].position.x, nodes[shapeKey].position.y, nodes[shapeKey].position.z]} geometry={nodes[shapeKey].geometry} {...props}>
                    </mesh>
                );
            }
            index++
        }
    }

    // Animation
    const { camera } = useThree()
    const MesheShapesGroup = useRef()
    const scene = SceneStatus()

    useLayoutEffect(() => {
        
        let animations = []
        
        if (scene == 'intro'){

        }
        else if(scene == 'intro-outro'){
            const Meshes = MesheShapesGroup.current.children
            for(var m = 0 ; m < Meshes.length; m++) {
                animations[m] = gsap.to(
                    Meshes[m].position,
                    {
                        x: choasPositions[m].x,
                        y: choasPositions[m].y,
                        z: choasPositions[m].z,
                        duration: CONDUCT.SCENEMANAGER.INTROOUTROTIME/1500,
                        ease: 'slow(0.8, 0.5, false)'
                    }
                )
            }

            setTimeout(()=>{
                CONDUCT.SCENEMANAGER.Scene('main')
            }, CONDUCT.SCENEMANAGER.INTROOUTROTIME)
        }
        else if(scene == 'main'){
            const Meshes = MesheShapesGroup.current.children
            for(var m = 0 ; m < Meshes.length; m++) {

                animations[m] = gsap.to(
                    Meshes[m].position,
                    {
                        scrollTrigger: {
                            trigger: 'body',
                            start: 'top 0%',
                            end: 'bottom 120%',
                            scrub: 1 // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                        },
                        x: orderPositions[m].x,
                        y: orderPositions[m].y,
                        z: orderPositions[m].z
                    }
                )
            }
            
            animations[animations.length] = gsap.to(
                camera.position,
                {
                    scrollTrigger : {
                        trigger: 'footer',
                        toggleActions: 'play pause resume reverse'
                    },
                    duration: 1,
                    x: 2.5
                }
            )
        }

        else {
        }
        
        return () => { 
            // cleanup code
            for(var a = 0 ; a < animations.length; a++) {
                animations[a].kill()
            }
        }

    }, [scene]); // <- empty dependency Array so it doesn't re-run on every render!

    return (
        <group ref={MesheShapesGroup}>
            {MesheShapes}
        </group>
    )
  }