'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {

  // Wether or not the true cursor should be visible
  hideTrueCursor: false,

  // Elements that apply the focus class on hover
  focusElements: ['a', 'button'],

  // Class applied when the true cursor is hovering over a focusElement
  focusClass: 'cursor--focused',
};