/**
 * --------------------------------------------------
 * Background Audio 
 * @module BackgroundAudio
 * --------------------------------------------------
 */


export default class BackgroundAudio {

    constructor() {
        this.isPlaying = false
        this.$player = $('audio')[0]
        this.$soundBtn = document.getElementById('sound')
        this.volume = 0.5
        this.$player.volume = this.volume
        this.addEventListeners()
    }

    play() {
        this.isPlaying = true
        this.$soundBtn.classList.add('playing')
        this.fadeIn()
    }

    stop() {
        this.isPlaying = false
        this.$soundBtn.classList.remove('playing')
        this.fadeOut()
    }

    fadeOut() {
        let self = this
        var intervalID = setInterval(function() {
	        // Reduce volume by 0.05 as long as it is above 0
	        // This works as long as you start with a multiple of 0.05!
	        if (self.volume > 0) {
	            self.volume = parseFloat ((self.volume - 0.02).toFixed(2))
                self.$player.volume = self.volume
	        } else {
	            // Stop the setInterval when 0 is reached
	            clearInterval(intervalID)
                self.volume = 0
                self.$player.pause()
	        }
        }, 150)
    }

    fadeIn() {
        this.$player.play()
        let self = this
        var intervalID = setInterval(function() {
	        // Reduce volume by 0.05 as long as it is above 0
	        // This works as long as you start with a multiple of 0.05!
	        if (self.volume < 0.5 ) {
	            self.volume = parseFloat ((self.volume + 0.02).toFixed(2))
                self.$player.volume = self.volume
	        } else {
	            // Stop the setInterval when 0.5 is reached
	            clearInterval(intervalID);
                self.volume = 0.5
	        }
        }, 150)
    }

    async attemptToPlay() { // called from scene manager
        const self = this
        this.playPromise = this.$player.play()
        return new Promise( (resolve, reject) => {
            // In browsers that don’t yet support this functionality,
            // playPromise won’t be defined.
            if (this.playPromise !== undefined) {
                this.playPromise.then(function() {
                    // Automatic playback started!
                    self.isPlaying = true
                    resolve()
                }).catch(function(error) {
                    reject(error)
                    // Automatic playback failed.
                    // Show a UI element to let the user manually start playback.
                })
            }
        })
    }

    addEventListeners() {
        const self = this
        this.$soundBtn.addEventListener('click', (e)=>{
            if (!self.isPlaying) {
                self.play()
            }
            else {
                self.stop()
            }
            e.preventDefault()
        })
    }
}