/**
 * --------------------------------------------------
 * Custom Scroller Animations 
 * @module Scroller
 * --------------------------------------------------
 */

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default class ScrollerAnimations {
    constructor(lenis){
        this.lenis = lenis
        gsap.registerPlugin(ScrollTrigger);
        // this.gsapUpdater()
    }

    greenSockOptions () {
        return {
            start: 'top 40%',
            end: 'bottom 50%',
            toggleActions: 'play none none none'
        }
    }

    gsapUpdater() {
        const self = this
        this.lenis.on('scroll', ScrollTrigger.update)

        gsap.ticker.add((time)=>{
            self.lenis.raf(time * 1000)
        })
        gsap.ticker.lagSmoothing(0)
    }

    runIntro() {
        
    }

    runAll() {
        this.HeroPositions()
        this.AboutUsPositions()
        this.ServicesPositions()
        this.PortfolioPositions()
        this.ApproachPositions()
        this.ClientPositions()
        this.footerPositions()
    }

    HeroPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "aside",
                ...this.greenSockOptions(),
                once: true,
                toggleActions: 'play none none none'
            }
        })

        // add animations and labels to the timeline
        tl
            .from("#logo", {opacity: 0, y: -30, duration: 0.75})
            .from("header .btn", {opacity: 0, y: -30, duration: 0.75}, '<0.5')
            .from("#hero h1", {opacity: 0, y: -30, duration: 0.75}, '<0.5')
            .from("#hero #tagline", {opacity: 0, x: -30, duration: 0.75}, '<0.5')
            .from("#hero .Scene-Bottom", {opacity: 0, y: -30, duration: 0.75}, '<0.5')
    }

    AboutUsPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".AboutUs",
                ...this.greenSockOptions()
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from(".AboutUs .subtitle", {opacity: 0, x: -30, duration: 0.75})
            .from(".AboutUs .AboutUs-Description-Primary", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".AboutUs .AboutUs-Description-Secondary", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".AboutUs .btn", {opacity: 0, y: 10, duration: 0.75}, '<0.25')
    }

    ServicesPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Services",
                ...this.greenSockOptions()
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from(".Services h2 span:nth-child(1)", {opacity: 0, x: -30, duration: 0.75})
            .from(".Services h2 span:nth-child(2)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Services h2 span:nth-child(3)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Services h2 span:nth-child(4)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Services h2 span:nth-child(5)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Services h2 span:nth-child(6)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
    }
    PortfolioPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Portfolio",
                ...this.greenSockOptions()
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from(".Portfolio .subtitle", {opacity: 0, x: -30, duration: 0.75})
            .from(".Portfolio .Portfolio-Description", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-Content", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-List li:nth-child(1)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-List li:nth-child(2)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-List li:nth-child(3)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-List li:nth-child(4)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Portfolio .Portfolio-Clients-List li:nth-child(5)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
    }

    ApproachPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Approach",
                ...this.greenSockOptions()
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from(".Approach .subtitle", {opacity: 0, x: -30, duration: 0.75})
            .from(".Approach .Approach-Description-Primary", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Approach .Approach-Steps .Approach-Step:nth-child(1)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Approach .Approach-Step-Visual", {opacity: 0, x: -30, duration: 0.75}, '<0.25')
            .from(".Approach .Approach-Steps .Approach-Step:nth-child(2)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Approach .Approach-Steps .Approach-Step:nth-child(3)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
        
        let fixedScrollTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.Approach-Step-Visual',
                pin: true, // pin the trigger element while active
                start: 'top 20%', // when the top of the trigger hits the top of the viewport
                end: 'bottom 80%',
                scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // snap: {
                //     snapTo: 'labels', // snap to the closest label in the timeline
                //     duration: { min: 0.2, max: 2 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //     ease: 'power1.inOut' // the ease of the snap animation ("power3" by default)
                // }
            }
        });

        let approachStepImages = gsap.utils.toArray('.Approach-Step-Image svg');
        fixedScrollTimeline.addLabel('start')
        for(let s = 0 ; s < approachStepImages.length; s++){
            if (s !== 0) { 
                fixedScrollTimeline.fromTo(approachStepImages[s],{ opacity: 0 }, {opacity: 1},  ">")
            }
            if (s !== approachStepImages.length-1) { 
                fixedScrollTimeline.addLabel('step-'+s)
                fixedScrollTimeline.fromTo(approachStepImages[s],{ opacity: 1 }, {opacity: 0},  ">")
            }
        }
    }

    ClientPositions() {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".Clients",
                ...this.greenSockOptions()
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from(".Clients .subtitle", {opacity: 0, x: -30, duration: 0.75})
            .from(".Clients .Clients-Description", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(1)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(2)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(3)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(4)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(5)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(6)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(7)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(8)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(9)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
            .from(".Clients .Clients-List li:nth-child(10)", {opacity: 0, y: -30, duration: 0.75}, '<0.25')
    }

    footerPositions(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "footer",
                start: 'top 65%'
            }
        });
        
        // add animations and labels to the timeline
        tl
            .from("footer", {opacity: 0, y: 30, duration: 0.75})
    }

    parallax () {
        // gsap.to('[data-speed]', {
        //     y: (i, el) => (1 - parseFloat(el.getAttribute('data-speed'))) * ScrollTrigger.maxScroll(window) ,
        //     ease: "none",
        //     scrollTrigger: {
        //         start: 0,
        //         end: "max",
        //         invalidateOnRefresh: true,
        //         scrub: 0
        //     }
        // })

        // const scenetimeline = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: '.Scene',
        //         start: 'top middle',
        //         end: 'bottom top',
        //         inertia: false,
        //         scrub: 2,
        //         markers: true
        //     }
        // });

        // gsap.utils.toArray('[data-speed]').forEach(elm => {
        //     const depth = elm.dataset.speed;
        //     const movement = -(elm.offsetHeight * depth)
        //     scenetimeline.to(elm, {y: movement, ease: 'none'}, 0)
        // });
    }
}