/**
 * --------------------------------------------------
 * Custom Navigation Dots 
 * @module Navigator
 * --------------------------------------------------
 */

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class Navigator {

    constructor() {

        // cache dom
        this.$navigation = $('nav')
        const Scenes = document.querySelectorAll('.Scene')
        const self = this

        // handle scroll changes
        for (let s = 0 ; s < Scenes.length; s++){
            let anchorElm = document.createElement('a');
            anchorElm.setAttribute('href','#')
            this.$navigation[0].appendChild(anchorElm);
            
            Scenes[s].dataset.sceneIndex = s
            ScrollTrigger.create({
                trigger: Scenes[s],
                start: 'top 40%',
                end: 'bottom 40%',
                onEnter: SceneTriggerPoint => {
                    if(SceneTriggerPoint.isActive) {
                        self.setNavigation(SceneTriggerPoint.trigger.dataset.sceneIndex)
                    }
                },
                onEnterBack: SceneTriggerPoint => {
                    if(SceneTriggerPoint.isActive) {
                        self.setNavigation(SceneTriggerPoint.trigger.dataset.sceneIndex)
                    }
                }
            })
        }

        // handle down arrow
        const $arrow = $('#downarrow')
        ScrollTrigger.create({
            trigger: '#hero',
            start: 'top 40%',        
            end: 'bottom 40%',
            onEnter: SceneTriggerPoint => {
                gsap.to($arrow, {opacity: 1, y: 10, duration: 0.75})
            },
            onEnterBack: SceneTriggerPoint => { 
                gsap.to($arrow, {opacity: 1,  y: 10, duration: 0.75})
            },
            onLeave: SceneTriggerPoint => { 
                gsap.to($arrow, {opacity: 0, y: 20, duration: 0.75})
            }
        })
    }

    setNavigation (index) {
        this.$navigation.find('a.active').removeClass('active')
        $(this.$navigation.find('a')[index]).addClass('active')
    }

}