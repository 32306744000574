/**
 * --------------------------------------------------
 * Conduct Application
 * @module Conduct
 * --------------------------------------------------
 */

// modules
import Loader from './modules/loader'
import Scroller from './modules/scroller'
import Navigator from './modules/navigator'
import SceneManager from './modules/scenemanager'
import BackgroundAudio from './modules/backgroundaudio'
import { createRoot } from 'react-dom/client'
import Puzzle from './react/Puzzle'

// execute
export default {

    load () {
        const self = this
        $(function() {
            self.$html = document.getElementsByTagName('html')[0]
            self.setup()
            self.loadSections()
            self.reactfiber()
        })
        return this
    },

    setup () {
        var self = this
        this.LOADER = new Loader()
        this.SCROLLER = new Scroller() // we should wait to set scroller after everything is loaded
        new Navigator()
        this.BACKGROUNDAUDIO = new BackgroundAudio()
        this.SCENEMANAGER = new SceneManager()
        this.LOADER.loaded = this.SCENEMANAGER.run.bind(this.SCENEMANAGER)
    },

    loadSections() {
        require('./modules/cursor')
        // sections
        require('./sections/portfolio')
        $('a').on('click', (e)=>{ if(e.currentTarget.getAttribute('href').substring(0, 1) == '#') e.preventDefault()})
    },

    reactfiber() {
        createRoot(document.getElementById('MainAnimator')).render(
            <Puzzle />
        )
    }
    
}