

/**
 * --------------------------------------------------
 * Custom cursor 
 * 
 * @description require once to include executing code
 * --------------------------------------------------
 */

(() =>{
    // getting library
    const CustomCursor = require('custom-cursor.js').default

    // caching elements
    const $sound = document.getElementById('sound')
    const $soundOn = document.getElementsByClassName('Cursor-Main--sound-on')[0]
    const $soundOff = document.getElementsByClassName('Cursor-Main--sound-off')[0]

    const isPlaying =() => { return ((' ' + $sound.className + ' ').indexOf(' ' + 'playing'+ ' ') > -1 ) }

    // Following options represent the defaults
    const options = {
        // Whether or not the true cursor should be hidden when the custom cursor is initialized
        hideTrueCursor: false,

        // Array of DOM elements/selector strings that add interactions on hover
        focusElements: [
            {
                elements: 'a#sound', // Can be nodelist or selector
                focusClass: 'cursor--focused-volume',
                mouseenter: () => {
                    isPlaying() ?
                        $soundOff.classList.add('show') : 
                        $soundOn.classList.add('show') ;
                },
                mouseleave: () => { // cleanup 
                    $soundOff.classList.remove('show')
                    $soundOn.classList.remove('show') 
                }
            },
            'button','a'
        ],

        // Class applied when the true cursor is hovering over a focusElement
        focusClass: 'cursor--focused'
    } 


    // Initialize
    const customCursor = new CustomCursor('.Cursor-Main', options)
    if (window.ontouchstart === undefined) {
        customCursor.setPosition(-30, -30).initialize()
        new CustomCursor('.Cursor-Border', {}).setPosition(-30, -30).initialize()
    }

    // Catch click event for sound to update cursor
    $sound.addEventListener('click', e => {
        console.log(isPlaying())
        $soundOff.classList.remove('show')
        $soundOn.classList.remove('show') 
        isPlaying() ?
            $soundOff.classList.add('show') : 
            $soundOn.classList.add('show') ;
    })


})()