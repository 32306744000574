/**
 * --------------------------------------------------
 * Portfolio Section JS 
 * 
 * @description require once to include executing code
 * --------------------------------------------------
 */

(($) => {

    // cache variables
    const $list = document.getElementsByClassName('Portfolio-Clients-Link')
    
    let $activeCarousel = null
    let scrollLength = 0
    
    // add listeners
    for( let l = 0 ; l < $list.length; l++) {
        $list[l].addEventListener('mouseover', (e) => {
            // show carousel
            $activeCarousel = document.getElementById( (e.target.dataset.target).substring(1))
            $activeCarousel.classList.add('active')
            // set carousel position
            $activeCarousel.style.top = ( e.target.getBoundingClientRect().top + 160)  + 'px'
            // hide when user scrolls
            scrollLength = document.scrollingElement.scrollTop
            document.addEventListener('scroll', calcScrollDistance)
        })
        $list[l].addEventListener('mouseleave', (e) => {
            hideCarousel()
        })
    }

    // hide when user scrolls 60 pixels in either direction
    let calcScrollDistance = function(e){
        if (Math.abs(scrollLength - document.scrollingElement.scrollTop)  > 60) {
            hideCarousel()
        }
    }

    // hide active carousel when user mouses away or scrolls passed 60pixels
    let hideCarousel = function() {
        if( $activeCarousel ){
            $activeCarousel.classList.remove('active')
            document.removeEventListener('scroll', calcScrollDistance)
        }
    }

})($)
