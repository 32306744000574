/**
 * --------------------------------------------------
 * Custom Asset Loader 
 * @module Loader
 * --------------------------------------------------
 */
import * as THREE from 'three'

export default class Loader {

    constructor() {
        const self = this
        this.$loader = document.getElementById('Loader')
        this.$loaderProgress = document.getElementsByClassName('Loader-progress')[0]
        this.$loaderText = document.getElementsByClassName('Loader-text')[0]
        this.isLoaded = false
        this.loaderWeights = [0.5,0.5]
        this.loaderPercentages = [0, 0]
        this.setupLoadingInterval()

        Promise.all([this.loadImages(),this.loadThreeJS()])
        .then(()=> {
            self.onLoad()
        })
    }

    setupLoadingInterval() {
        const self = this
        let lastValue = 0
        let newValue = 0
        this.loaderInterval = setInterval(() => {
            newValue = ((self.loaderPercentages[0] * self.loaderWeights[0]) + (self.loaderPercentages[1] * self.loaderWeights[1]))
            if( newValue <= lastValue && lastValue < 0.99)
                newValue = lastValue + 0.01
            else if(lastValue == .99) {
                newValue == .99
            }
            self.$loaderText.innerHTML = '' + Math.round( newValue * 100) + '%'
            self.$loaderProgress.style.transform = 'scaleX('+newValue+')'
            lastValue = newValue
        }, 500);
    }

    onLoad () {
        const self = this

        clearInterval(this.loaderInterval)
        CONDUCT.$html.classList.add('loading-done')
        this.$loaderText.innerHTML = '' + Math.round(100) + '%'
        this.$loaderProgress.style.transform = 'scaleX('+1+')'

        setTimeout(()=>{
            self.loaded ? self.loaded() : null
            self.animateIn()
        }, 1000)
    }

    async loadImages() {
        const self = this
        // Promise progress helper
        Promise.progress = async function progress (iterable, onprogress) {
            const promises = Array.from(iterable).map(this.resolve, this);
            let resolved = 0;
            const progress = increment => this.resolve(onprogress(new ProgressEvent('progress', { total: promises.length, loaded: resolved += increment })));
            await this.resolve();
            await progress(0);
            return this.all(promises.map(promise => promise.finally(() => progress(1))));
        }
        return new Promise( (resolveAll, rejectAll) => 
            Promise.progress(Array.from(document.images)
                .filter(img => !img.complete)
                .map(img => new Promise(resolve => { 
                    img.addEventListener('load', resolve);
                    img.addEventListener('error', resolve);
                })), (p) => {
                    self.loaderPercentages[1] = Math.round(p.loaded / p.total)
                    // console.log(`Waiting for images on page to load.. (${p.loaded}/${p.total})`, 0, p.total, p.loaded);
                }).then(() => {
                    self.loaderPercentages[1] = 1
                    resolveAll()
            })
        )
    }

    async loadThreeJS() {
        const self = this
        return new Promise( (resolve, reject)=> {    // Loaded
            resolve()
            // self.LoaderManager = new THREE.LoadingManager(
            //     () =>
            //     {
            //         self.loaderPercentages[0] = 1
            //         resolve()
            //     },
            
            //     // Progress
            //     (itemUrl, itemsLoaded, itemsTotal) =>
            //     {
            //         self.loaderPercentages[0] = Math.round(itemsLoaded/ itemsTotal)
            //     }
            // )
        })
    }

    animateIn () {
        CONDUCT.$html.classList.add('loading-animateIn')
        setTimeout(this._animateInHelper.bind(this), 1500)
    }

    _animateInHelper () {
        CONDUCT.$html.classList.remove('loading-animateIn')
        CONDUCT.$html.classList.remove('loading')
        CONDUCT.$html.classList.remove('loading-done')
    }
}