import { useState, useEffect } from 'react';

export default function SceneStatus() {
    const [sceneStatus, setSceneStatus] = useState('intro');

    window.CONDUCT.SCENEMANAGER.Scene = (value) => {
        setSceneStatus(value);
    };

    return sceneStatus;
}