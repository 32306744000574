/**
* --------------------------------------------------
* Main Application
* @module App
* --------------------------------------------------
*/

//----------------------------------------
// Global library dependencies
//----------------------------------------

window.$ = require('jquery')

//----------------------------------------
// application dependencies
//----------------------------------------

import Conduct from './conduct'

//----------------------------------------
// Conduct entry point
//----------------------------------------

window.CONDUCT = Conduct.load() || {}